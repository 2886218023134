.campaign-title {
    text-align: center;
    font-size: 3.0rem;
    color: #0e388c;
    padding-top: 40px;
    font-weight: 800;
}

.campaign-section .fa-circle {
    scale: 0.6;
    color: #1b479c;
    margin-right: 10px;
}

.campaign-description {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 10px;
}

.campaign-cards {
    border: 1px solid #1b479c;
    border-radius: 10px;
    padding: 30px;
    margin: 20px auto 60px auto;
    max-width: 800px;
}

.campaign-text {
    text-align: left;
    font-size: 2.0rem;
    color: #0e388c;
    margin: 0px auto;
    font-weight: 800;
}

.campaign-list {
    text-align: left;
    margin-top: 10px;
    line-height: 1.8rem;
}

.remaining-days {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 10px;
}

.campaign-action {
    display: flex;
    align-items: center;
}

.details-button {
    display: inline-flex;
    align-items: center;
    color: #6aa3d4;
    font-weight: bold;
    text-decoration: none;
    border: 1px solid #d0e4f5;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 0.9rem;
    transition: background-color 0.3s, color 0.3s;
}

.details-button:hover {
    background-color: #e1f0fa;
    color: #4287b7;
}

.pdf-icon {
    margin-left: 8px;
    font-size: 1rem;
}

@media (max-width: 768px) {
    .campaign-section {
        padding: 20px;
        margin: 50px;
    }

    .campaign-title {
        font-size: 3.0rem;
        margin-bottom: 8px;
    }

    .campaign-description {
        font-size: 1.2rem;
        margin-bottom: 15px;
    }

    .campaign-cards {
        border: 1px solid #1b479c;
        border-radius: 10px;
        padding: 30px;
        margin: 40px auto 60px auto;
    }

    .details-button {
        padding: 6px 10px;
        font-size: 0.8rem;
    }

    .pdf-icon {
        font-size: 0.9rem;
    }
}

@media (max-width: 430px) {
    .campaign-section {
        padding: 15px;
        margin: 0px;
    }

    .campaign-title {
        text-align: left;
        font-size: 3.0rem;
        margin: 30px 8px 8px 15px;
    }

    .campaign-description {
        text-align: left;
        font-size: 1.2rem;
        margin: 0px 15px;
    }

    .campaign-text {
        text-align: left;
        font-size: 1.5rem;
        margin: 0px auto;
    }

    .campaign-list {
        font-size: 0.9rem;
    }

    .details-button {
        padding: 4px 8px;
        font-size: 0.7rem;
    }

    .pdf-icon {
        font-size: 0.8rem;
    }
}