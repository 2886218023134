.contact-form-container {
    width: 80%;
    margin: 100px auto;
    padding: 20px;
    border-radius: 10px;
}

.contact-form-container h1 {
    font-size: 3rem;
    color: #0e388c;
    font-weight: 800;
    margin-bottom: 50px;
}

.form-group {
    margin-bottom: 50px;
    text-align: left;
}

.form-group:nth-of-type(3) {
    margin-bottom: 10px;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
    font-size: 1.3rem;
}

input[type="text"],
input[type="email"],
textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    margin-bottom: 10px;
    height: 50px;
}

textarea {
    resize: vertical;
}

.name-fields {
    display: flex;
    gap: 20px;
}

.name-fields input {
    flex: 1;
}

.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.checkbox-group label {
    font-weight: 400; /* Adjust the font-weight as needed */
    font-size: 1.2rem;
}
.checkbox-group label {
    display: flex;
    align-items: center;
    gap: 5px;
    width: calc(33.333% - 10px); /* Adjust width to fit three columns with gap */
    box-sizing: border-box;
}

.checkbox-group input {
    transform: scale(1.5);
    margin-right: 20px;
}

.resources input {
    transform: scale(1.5);
    margin-right: 20px;
}

.free-text textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    margin-bottom: 10px;
    height: 100px;
}

button {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: #1b479c;
    font-weight: 800;
    width: 300px;
    height: 100px;
    background-color: #ffffff;
    border: 2px solid #1b479c;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, color 0.3s ease;
}

button:hover {
    background-color: #1b479c;
    color: #ffffff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Ensure text color inside button changes on hover */
button:hover {
    color: #ffffff; /* Invert to white */
}

button::after {
    content: "\f058";
    font-family: "Font Awesome 6 Free"; /* フォントファミリーを指定 */
    font-weight: 900; /* Solid アイコンの場合、font-weight を 900 に設定 */
    color: #1b479c;
    font-size: 2rem;
    margin-left: 30px;
    vertical-align: middle;
    transition: color 0.3s ease; /* Smooth transition for color change */
}

/* Hover effect for ::after icon */
button:hover::after {
    color: #ffffff; /* Invert icon color on hover */
}