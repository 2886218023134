/* LocalGovernmentSection.css */

.learning-plan-section {
    padding: 40px;
    margin: 20px auto 0;
    text-align: center;
	background-color: #f9f9f9;
}

.learning-plan-section p {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 10px;
}

.learning-plan-title {
    text-align: center;
    font-size: 3.0rem;
    color: #0e388c;
    padding-top: 40px;
    font-weight: 800;
}

.learning-plan-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 40px;
    width: 100%;
}

.learning-plan-item {
    flex: 1 1 calc(45% - 20px); /* パソコンでは3列 */
    max-width: calc(45% - 20px); /* 最大幅 */
    text-align: left;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
    background-color: #f9f9f9;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
	background-color: #ffffff;
}

.learning-plan-item:hover {
    transform: scale(1.03);
}

.learning-plan-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
	margin-top: 10px;
}

.learning-plan-item h3 {
    font-size: 1.5em;
    margin: 10px 0 0 10px;
}

.learning-plan-item p {
    font-size: 1em;
    margin: 0 0 10px 20px;
    color: #666;
}

.fa-circle {
    scale: 0.6;
    color: #1b479c;
    margin-right: 10px;
}

/* レスポンシブ対応 */
@media (max-width: 768px) {
    .learning-plan-item {
        flex: 1 1 100%; /* スマホでは1列 */
        max-width: 100%;
    }

    .learning-plan-image {
		width: 100%;
		height: auto;
		object-fit: cover;
		border-radius: 10px;
		margin-top: 10px;
	}
}

@media (max-width: 480px) {
    .learning-plan-section {
        padding: 15px;
    }

	.learning-plan-title {
		font-size: 3rem;
		padding-top: 20px;
		text-align: left;
		padding-left: 20px;
	}

	.learning-plan-section p {
		padding: 10px 0 0 20px;
		text-align: left;
		font-size: 1.0rem;
	}

	.learning-plan-item h3{
		font-size: 1.2rem;
		padding: 10px;
		text-align: left;
	}
}