.receive-a-job-section {
    padding: 60px 20px;
}

.receive-a-job-section p {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 10px;
}

.receive-a-job-title {
    text-align: center;
    font-size: 3.0rem;
    color: #0e388c;
    padding-top: 40px;
    font-weight: 800;
    margin-bottom: 0;
}

.receive-a-job-content {
    display: flex;
    align-items: center;
    gap: 40px;
    margin: 40px 120px;
}

.receive-a-job-image-container {
    flex: 1;
}

.receive-a-job-image {
    width: 100%;
    border-radius: 20px;
}

.receive-a-job-text {
    flex: 1;
    padding: 20px;
    text-align: left;
}

.receive-a-job-text .point-text {
    color: #0e388c;
    font-size: 1.2rem;
    margin-bottom: 10px;
    opacity: 0.5;
}

.receive-a-job-text h3 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 15px;
    font-weight: bold;
}

.receive-a-job-text p {
    font-size: 1.1rem;
    color: #333;
    line-height: 1.6;
}

@media (max-width: 1024px) {
    .receive-a-job-content {
        gap: 20px;
        margin: 40px;
    }
}

@media (max-width: 768px) {
    .receive-a-job-content {
        flex-direction: column;
        gap: 10px;
        padding-bottom: 20px;
        align-items: flex-start;
    }

    .receive-a-job-description {
        padding: 0 40px;
    }

    .receive-a-job-image {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .receive-a-job-section {
        padding: 40px 10px;
    }

    .receive-a-job-title {
        font-size: 1.8rem;
        padding-top: 20px;
        text-align: left;
        padding-left: 20px;
    }

    .receive-a-job-description {
        font-size: 1.0rem;
        text-align: left;
        padding: 0 20px;
    }

    .receive-a-job-content {
        margin: 50px 10px;
        gap: 20px;
    }

    .receive-a-job-text {
        padding: 10px;
    }

    .receive-a-job-text h3 {
        font-size: 1.8rem;
    }

    .receive-a-job-text .point-text {
        font-size: 0.8rem;
        font-weight: 800;
    }

    .receive-a-job-text p {
        padding-top: 0px;
        font-size: 0.9rem;
        font-weight: 400;
    }
}