.toggle-menu-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9fc;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.toggle-menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px;
}

.toggle-menu-header.open {
    background-color: #f9f9f9;
}

.menu-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}

.toggle-arrow {
    font-size: 1.2rem;
    color: #333;
    transition: transform 0.3s ease;
}

.toggle-arrow.up {
    transform: rotate(180deg);
}

.menu-content {
    padding: 15px;
    border-top: 1px solid #ddd;
    text-align: left;
    color: #333;
}

.menu-content h4 {
    font-size: 1.3rem;
    margin-bottom: 0;
}

.menu-content .style-none {
    list-style-type: none;
}

.menu-link {
    display: block;
    margin-bottom: 15px;
    color: #2e5cb8;
    text-decoration: underline;
}

.menu-link:hover {
    text-decoration: none;
}

.menu-content ul {
    padding: 0;
}

.menu-content ul li {
    margin:  7px 0px 0px 15px;
    font-size: 1rem;
}

.menu-content ul li strong {
    color: #333;
}