.courses-section {
    padding: 40px 0;
    background-color: #f9f9f9;
}

.courses-section p {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 10px;
}

.course-section-title {
    text-align: center;
    /* センタリング */
    font-size: 3.0rem;
    /* タイトルのフォントサイズを大きく */
    /* 下部に余白を追加 */
    color: #0e388c;
    padding-top: 40px;
    font-weight: 800;
}

.course-section-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
    position: relative;
}

.course-section-title-p {
    margin-bottom: 60px;
    color: #333;
}

.course-section-item.reverse {
    flex-direction: row-reverse;
    /* 偶数番目のアイテムを左右入れ替え */
}

.course-section-item.reverse .course-section-image {
    left: 0;
    right: auto; /* reverseの場合、左端まで配置 */
}

.course-section-text {
    width: 50%;
    padding: 20px 80px;
    text-align: left;

}

.course-section-text h3 {
    color: #0e388c;
    font-size: 2rem;
    margin-bottom: 10px;
}

.course-section-text ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.course-section-text ul li {
    position: relative;
    font-size: 1.1rem;
    line-height: 2;
    color: #333;
    font-weight: 600;
    padding: 10px 0;
    border-bottom: 1px solid #333; /* liのテキストに下線を追加 */
    text-align: left;
}

.fa-circle {
    scale: 0.6;
    color: #0e388c;
    margin-right: 10px;
}

.course-section-image {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50vw;
    height: 100%;
}

.course-section-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
}

.course-section-item .course-section-image img {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    border-top-right-radius: 0; /* 右側の画像の右端はボーダーラディウスを0 */
    border-bottom-right-radius: 0;
}

.course-section-item.reverse .course-section-image img {
    border-top-left-radius: 0; /* 左側の画像の左端はボーダーラディウスを0 */
    border-bottom-left-radius: 0;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.course-section-notice {
    margin: 100px 0 60px 0;
}

.course-section-notice h3{
    color: #0e388c;
    font-size: 2rem;
    margin-bottom: 10px;
}

.course-section-notice p{
    font-size: 1.1rem;
    line-height: 1.2;
    color: #333;
    font-weight: 600;
}

@media (max-width: 768px) {
    .course-section-text {
        padding: 20px 40px;
    }

}

@media (max-width: 480px) {
    .courses-section {
        padding: 20px 0;
    }

    .course-section-title {
        font-size: 2.3rem;
        padding-top: 20px;
        text-align: left;
        padding-left: 20px;
    }

    .course-section-title-p {
        font-size: 1.0rem;
        text-align: left;
        padding: 0 20px;
    }

    .course-section-item {
        display: flex;
        flex-direction: column; /* スマホで h3 -> ul -> img の順に表示 */
        align-items: center; /* センター揃え */
        margin-bottom: 30px;
    }

    .course-section-item.reverse {
        flex-direction: column;
    }

    .course-section-text {
        width: 100%;
        padding: 10px 20px;
        text-align: center;
    }

    .course-section-image {
        width: 100%;
        position: relative;
        height: auto;
    }

    .course-section-image img {
        width: 100%;
        height: 100px;
        margin: 0 auto;
    }

    .course-section-item .course-section-image img {
        border-radius: 0px;
    }

    .course-section-item.reverse .course-section-image img {
        border-radius: 0px;
    }

    .course-section-text h3 {
        font-size: 1.5rem;
    }

    .course-section-text ul li {
        font-size: 1rem;
        padding: 5px 0;
    }

    .course-section-notice {
        margin: 50px 0 30px 0;
    }

    .course-section-notice h3 {
        font-size: 1.5rem;
    }

    .course-section-notice p {
        font-size: 1rem;
        text-align: left;
        padding: 0 20px;
    }
}