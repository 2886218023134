.contact-confirm-container {
    width: 80%;
    margin: 100px auto;
    padding: 20px;
    border-radius: 10px;
}

.confirm-details {
    margin: 50px 0;
}

.confirm-details p {
    font-size: 1.2rem;
    margin-bottom: 15px;
    text-align: left;
}

.confirm-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.confirm-buttons button {
    font-size: 1.5rem;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #1b479c;
    color: white;
    border: none;
    cursor: pointer;
}

.confirm-buttons .fixes {
    background-color: #f9f9f9;
    color: #333;
}

.confirm-buttons button::after {
    color: #ffffff;
}

.confirm-buttons .fixes::after {
    color: #1b479c;
}

.confirm-buttons button:hover {
    background-color: #355ba8;
}

.confirm-buttons .fixes:hover {
    background-color: #355ba8;
    color: #ffffff;
}

.confirm-buttons .fixes:hover::after {
    color: #ffffff;
}