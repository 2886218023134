/* FlowSection.css */

/* 全体のスタイル */
.flow-section {
    padding: 60px 20px;
}

.flow-section p {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 10px;
}

.flow-title {
    text-align: center;
    font-size: 3.0rem;
    color: #0e388c;
    padding-top: 40px;
    font-weight: 800;
}

.main-flow {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flow-block {
    margin: 40px 0 10px 0;
    text-align: center;
}

.flow-block h3 {
    font-size: 3rem;
    font-weight: 700;
    padding: 10px 20px;
    color: #333;
}

.sub-block-wrapper {
    position: relative;
    width: 100%;
    margin-bottom: 40px;
}

/* サブブロックのスタイル */
.sub-block-container {
    display: flex;
    transition: transform 0.5s ease-in-out;
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox用 */
    -ms-overflow-style: none; /* IE用 */
    gap: 20px; /* 左右の要素間のスペース */
}

.sub-block-container::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}

/* すべての要素に box-sizing: border-box を適用 */
*,
*::before,
*::after {
    box-sizing: border-box;
}

.sub-block.horizontal-layout {
    min-width: 50%;
    display: flex;
    align-items: flex-start; /* 上揃えに変更 */
    background-color: #f9f9f9;
    margin: 20px; /* 上下のマージンのみ */
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    gap: 40px; /* 左右の要素間のスペース */
    padding: 20px; /* 内側のパディング */
}

/* 左右のコンテンツ */
.sub-content-left,
.sub-content-right {
    flex: 1; /* 均等に幅を配分 */
}

.sub-content-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* 左寄せ */
    margin: 15px;
}

.sub-content-left h4 {
    font-size: 2rem;
    font-weight: bold;
    color: #0e388c;
    text-align: left;
}

.sub-content-left .sub-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.sub-content-right {
    display: flex;               /* Flexbox を使用 */
    flex-direction: column;      /* 縦方向に並べる */
    justify-content: center;     /* 垂直方向に中央揃え */
    text-align: left;
    height: 100%;
}

.sub-content-right p {
    font-size: 1.1rem;
    font-weight: 600;
    color: #333;
    margin: 0 0 20px 0;
}

.sub-content-right ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sub-content-right ul li {
    position: relative;
    font-size: 1.0rem;
    margin-bottom: 10px;
    line-height: 1.5;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #f9f9f9;
    width: 40px;
    height: 40px;
    background-color: #1b479c;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    z-index: 1;
}

.left-arrow {
    left: 10px;
}

.right-arrow {
    right: 10px;
}

.dots-container {
    text-align: center;
    margin-top: 20px;
}

.dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 5px;
    cursor: pointer;
}

.dot.active {
    background-color: #0e388c;
}

@media (max-width: 1024px) {
    .sub-block.horizontal-layout {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .sub-content-left,
    .sub-content-right {
        width: 100%;
    }

    .sub-content-left h4,
    .sub-content-right p,
    .sub-content-right ul {
        text-align: left;
    }

    .sub-content-right ul li {
        padding-left: 0;
    }

    .sub-content-right ul li::before {
        display: none;
    }
}

/* レスポンシブ対応 */
@media (max-width: 768px) {
    .sub-block.horizontal-layout {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .sub-content-left,
    .sub-content-right {
        width: 100%;
    }

    .sub-content-left h4,
    .sub-content-right p,
    .sub-content-right ul {
        text-align: left;
    }

    .sub-content-right ul li {
        padding-left: 0;
    }

    .sub-content-right ul li::before {
        display: none;
    }
}

@media (max-width: 480px) {
    .flow-section {
        padding: 40px 10px;
    }

    .flow-section p {
        text-align: left;
        padding: 0 20px;
    }

    .flow-title {
        font-size: 3rem;
        padding-top: 20px;
        text-align: left;
        padding-left: 20px;
    }

    .flow-block h3 {
        font-size: 2.5rem;
        padding: 5px 10px;
    }

    .sub-block.horizontal-layout {
        min-width: 100%;
        margin: 10px 0;
        padding: 10px;
        gap: 20px;
    }

    .sub-content-left h4 {
        font-size: 2.0rem;
        padding-left: 10px;
    }

    .sub-content-right p {
        font-size: 1.3rem;
        text-align: left;
        padding-left: 10px;
    }

    .sub-content-right ul li {
        font-size: 1.1rem;
        text-align: left;
        padding-left: 10px;
    }

    .main-flow .arrow {
        width: 45px;
        height: 45px;
    }

    .dot {
        width: 10px;
        height: 10px;
    }
}