.concept-section {
    padding: 40px;
    text-align: center;
    background-color: #f9f9f9;
}

.concept-section p {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 10px;
}

.concept-title {
    text-align: center;
    /* センタリング */
    font-size: 3.0rem;
    /* タイトルのフォントサイズを大きく */
    /* 下部に余白を追加 */
    color: #0e388c;
    padding-top: 40px;
    font-weight: 800;
}

.concept-grid {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* デフォルトでは1列（スマホ向け） */
    grid-gap: 20px;
}

.concept-item {
    text-align: left;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
    background-color: #f9f9f9;
    padding: 20px;
}

.concept-item:hover {
    transform: scale(1.03);
}

.concept-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
}

.concept-item h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.concept-item p {
    font-size: 1em;
    color: #666;
}

.fa-circle {
    scale: 0.8;
    color: #1b479c;
    margin-right: 10px;
}

/* 中型画面（タブレットなど）では2列にする */
@media (max-width: 768px) {
    .concept-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}

/* 小型画面（スマホなど）では1列にする */
@media (max-width: 480px) {
    .concept-section {
        padding: 20px;
    }

    .concept-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .concept-title {
        font-size: 3.0rem;
        padding-top: 20px;
        text-align: left;
        padding-left: 20px;
    }

    .concept-section p {
        font-size: 1.0rem;
        text-align: left;
        padding: 0 20px;
    }

    .concept-item {
        padding: 10px 0;
    }

    .concept-image {
        height: 300px;
    }
}