.experience-section {
    padding: 40px 0;
}

.experience-section p {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 10px;
}

.experience-section-title {
    text-align: center;
    /* センタリング */
    font-size: 3.0rem;
    /* タイトルのフォントサイズを大きく */
    /* 下部に余白を追加 */
    color: #0e388c;
    padding-top: 40px;
    font-weight: 800;
}

.experience-section-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
    position: relative;
}

.experience-section-title-p {
    margin-bottom: 60px;
    color: #333;
}

.experience-section-item.reverse {
    flex-direction: row-reverse;
    /* 偶数番目のアイテムを左右入れ替え */
}

.experience-section-item.reverse .experience-section-image {
    left: 0;
    right: auto; /* reverseの場合、左端まで配置 */
}

.experience-section-text {
    width: 50%;
    padding: 20px 80px;
    text-align: left;

}

.experience-section-text h3 {
    color: #0e388c;
    font-size: 2rem;
    margin-bottom: 10px;
}

.experience-section-text p {
    position: relative;
    font-size: 1.3rem;
    line-height: 2;
    color: #333;
    font-weight: 500;
    padding: 10px 0;
    text-align: left;
}

.fa-circle {
    scale: 0.6;
    color: #0e388c;
    margin-right: 10px;
}

.experience-section-image {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50vw;
    height: 100%;
}

.experience-section-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
}

.experience-section-item .experience-section-image img {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    border-top-right-radius: 0; /* 右側の画像の右端はボーダーラディウスを0 */
    border-bottom-right-radius: 0;
}

.experience-section-item.reverse .experience-section-image img {
    border-top-left-radius: 0; /* 左側の画像の左端はボーダーラディウスを0 */
    border-bottom-left-radius: 0;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

@media (max-width: 768px) {
    .experience-section-item {
        flex-direction: column;
    }

    .experience-section-item.reverse {
        flex-direction: column;
    }

    .experience-section {
        padding: 20px 0;
    }

    .experience-section-title-p {
        font-size: 1.0rem;
        padding: 0 40px;
    }

    .experience-section-item {
        flex-direction: column;
        margin-bottom: 40px;
    }

    .experience-section-text {
        width: 100%;
        padding: 10px 20px;
        text-align: center;
    }

    .experience-section-text h3 {
        font-size: 1.8rem;
    }

    .experience-section-text p {
        font-size: 1.1rem;
    }

    .experience-section-image {
        width: 100%;
        position: relative;
        height: auto;
    }

    .experience-section-image img {
        width: 100%;
        height: 400px;
    }

    .experience-section-item .experience-section-image img,
    .experience-section-item.reverse .experience-section-image img {
        border-radius: 0px;
    }
}

@media (max-width: 480px) {
    .experience-section-item {
        flex-direction: column;
    }

    .experience-section-item.reverse {
        flex-direction: column;
    }

    .experience-section {
        padding: 20px 0;
    }

    .experience-section-title {
        font-size: 2.5rem;
        padding-top: 20px;
        text-align: left;
        padding-left: 20px;
    }

    .experience-section-title-p {
        font-size: 1.0rem;
        text-align: left;
        padding: 0 20px;
    }

    .experience-section-item {
        flex-direction: column;
        margin-bottom: 40px;
    }

    .experience-section-text {
        width: 100%;
        padding: 10px 20px;
        text-align: center;
    }

    .experience-section-text h3 {
        font-size: 1.8rem;
    }

    .experience-section-text p {
        font-size: 1.1rem;
    }

    .experience-section-image {
        width: 100%;
        position: relative;
        height: auto;
    }

    .experience-section-image img {
        width: 100%;
        height: 250px;
    }

    .experience-section-item .experience-section-image img,
    .experience-section-item.reverse .experience-section-image img {
        border-radius: 0px;
    }
}