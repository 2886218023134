/* LocalGovernmentSection.css */

.strengths-section {
    padding: 40px;
    margin: 20px auto 60px;
    text-align: center;
}

.strengths-section p {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 10px;
}

.strengths-title {
    text-align: center;
    font-size: 3.0rem;
    color: #0e388c;
    padding-top: 40px;
    font-weight: 800;
}

.strengths-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 40px;
    width: 100%;
}

.strengths-item {
    flex: 1 1 calc(33.33% - 20px); /* パソコンでは3列 */
    max-width: calc(33.33% - 20px); /* 最大幅 */
    text-align: left;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
    background-color: #f9f9f9;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.strengths-item:hover {
    transform: scale(1.03);
}

.strengths-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
}

.strengths-item h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.strengths-item p {
    font-size: 1em;
    color: #666;
}

.fa-circle {
    scale: 0.6;
    color: #1b479c;
    margin-right: 10px;
}

/* レスポンシブ対応 */
@media (max-width: 768px) {
    .strengths-item {
        flex: 1 1 100%; /* スマホでは1列 */
        max-width: 100%;
    }

    .strengths-image {
        height: 200px;
    }
}

@media (max-width: 480px) {
    .strengths-section {
        padding: 15px;
    }
}