.feature-page {
    padding: 40px 0 0 0;
}

sup {
    font-size: 0.6em;
    vertical-align: super;
}

.startup-slide {
    font-size: 1.2rem;
    margin-top: 40px;
}