.footer-container {
    background-color: #f8f8f8;
    padding: 40px;
    font-family: 'Noto Sans JP', sans-serif;
    color: #333;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
}

.footer-logo {
    display: flex;
    align-items: center;
}

.college-info {
    text-align: left;
}

.college-info h2 {
    font-size: 1.5rem;
    margin: 0;
    color: #333;
}

.college-info p {
    margin: 5px 0;
    font-size: 0.9rem;
    color: #666;
}

.footer-links {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.footer-links a {
    color: #1b479c;
    text-decoration: none;
    font-size: 0.9rem;
}

.footer-links a:hover {
    text-decoration: underline;
}

.footer-bottom {
    text-align: center;
    margin-top: 20px;
    font-size: 0.8rem;
    color: #666;
}

@media (max-width: 480px) {
    .college-info h2 {
            font-size: 1.3rem;
    }

    .footer-links {
        margin-top: 10px;
        line-height: 0.8rem;
    }

}