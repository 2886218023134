.cost-section {
    padding: 40px 80px;
    background-color: #fff;
}

.cost-section p {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 10px;
}

.cost-section h2 {
    text-align: center;
    /* センタリング */
    font-size: 3.0rem;
    /* タイトルのフォントサイズを大きく */
    /* 下部に余白を追加 */
    color: #0e388c;
    padding-top: 40px;
    font-weight: 800;
}

.cost-table {
    width: 80%;
    border-collapse: collapse;
    margin: 40px auto;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.cost-table th,
.cost-table td {
    padding: 15px 20px;
    text-align: center;
    font-size: 1.1rem;
    border: 1px solid #ddd;
}

.cost-table th {
    background-color: #dbe6f4;
    color: #0e388c;
    font-weight: bold;
}

.even-row {
    background-color: #fff;
}

.odd-row {
    background-color: #f0f0f0;
}

/* 左端の列の色を変更 */
.cost-table td:first-child {
    background-color: #1b479c;
    color: #fff; /* テキスト色を白に */
    font-weight: bold; /* 太字に */
  }

.cost-table td {
    font-size: 1.2rem;
    color: #333;
}

.cost-notice h3 {
    font-size: 1.2rem;
    color: #0e388c;
    font-weight: bold;
}

.cost-notice p {
    font-size: 1rem;
    color: #333;
}

@media (max-width: 1024px) {
    .cost-section {
        padding: 40px;
    }

    .cost-table {
        width: 100%;
        padding: 20px 0;
    }
}

@media (max-width: 768px) {
    .cost-section {
        padding: 20px;
    }

    .cost-table th,
    .cost-table td {
        padding: 10px;
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .cost-section {
        padding: 10px;
        position: relative;
    }

    .cost-section h2 {
        font-size: 3rem;
        padding-top: 20px;
        text-align: left;
        padding-left: 20px;
    }

    .cost-section p {
        font-size: 1.0rem;
        text-align: left;
        padding-left: 20px;
    }

    .cost-table-wrapper {
        width: 100%;
        overflow-x: auto;
    }

    .cost-table {
        width: 100%;
        min-width:600px;  /* スマホ用の最小幅 */
        margin: 20px 0;
    }

    .cost-table th,
    .cost-table td {
        padding: 8px;
        font-size: 0.8rem;
        white-space: nowrap;  /* テキストを1行に収める */
    }

    .cost-notice h3 {
        font-size: 1rem;
        text-align: left;
        padding-left: 20px;
    }

    .cost-notice p {
        font-size: 0.9rem;
        padding-top: 0;
    }

    .scroll-icon {
        position: absolute;
        right: 20px;
        top: 50%;
        font-size: 2rem;
        color: #0e388c;
        animation: bounce 1s infinite;
    }

    @keyframes bounce {
        0%, 100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-10px);
        }
    }
}