/* 全体のスタイルをリセット */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* h2タグをセンタリング */
.merit-section {
    background-color: #f9f9f9;
    padding-top: 60px;
}

.merit-title {
    text-align: center;
    font-size: 3.0rem;
    color: #0e388c;
    padding-top: 40px;
    font-weight: 800;
}

.merit-section p {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 10px;
}

.merit-section h3 {
    font-size: 3.0rem;
    font-weight: 800;
    padding-left: 10px;
    position: relative;
    z-index: 2;
    background-color: #f9f9f9;
}

.merit-section h4 {
    font-size: 1.0rem;
    line-height: 1.5;
    margin-bottom: 10px;
    font-weight: 400;
}

/* メリットのレイアウト */
.merit-item {
    display: flex;
    justify-content: space-between;
    align-items: top;
    margin: 40px 0 80px 0;
    padding: 20px;
}

.content-left {
    flex: 1;
    display: block;
}

.inner-content {
    position: relative;
    margin-left: 10vw;
    text-align: left;
}

.inner-content ul {
    list-style: none;
    color: #0e388c;
    font-weight: 600;
    line-height: 1.6;
    font-size: 1.5rem;
}

.inner-content ul li::before {
    content: '\f058';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    margin-right: 15px;
}

.number {
    position: absolute;
    top: -45px;
    left: -55px;
    width: 80px;
    height: 80px;
    background-color: transparent;
    border: 3px solid #0e388c;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.3rem;
    font-weight: 500;
    color: #0e388c;
    z-index: 1;
}

.image {
    flex: 1;
}

.image img {
    width: 80%;
    height: auto;
}

.image-container {
    position: relative;
    min-height: 400px;
}

.small-image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    opacity: 0;
    transform: translate(-50%, -70%) scale(0.1);
    transition: transform 0.5s, opacity 0.5s;
}

/* アニメーション時 */
.small-image.fly-out:nth-child(1) {
    transform: translate(-50%, -110%) scale(0.25);
    opacity: 1;
}

.small-image.fly-out:nth-child(2) {
    transform: translate(-90%, -95%) scale(0.25);
    opacity: 1;
}

.small-image.fly-out:nth-child(3) {
    transform: translate(-10%, -90%) scale(0.25);
    opacity: 1;
}

.small-image.fly-out:nth-child(4) {
    transform: translate(-100%, -60%) scale(0.25);
    opacity: 1;
}

.small-image.fly-out:nth-child(5) {
    transform: translate(0%, -60%) scale(0.25);
    opacity: 1;
}

/* タブレットサイズ（最大幅768px） */
@media (max-width: 768px) {
    .merit-item {
        flex-direction: column;
        align-items: center;
        margin-top: 0;
        margin-bottom: 10px;
    }

    .content-left,
    .image-container {
        width: 100%;
        margin: 0;
    }

    .inner-content {
        margin: 0 50px;
        text-align: center;
    }

    .merit-title {
        padding-left: 20px;
    }

    .merit-section p {
        padding: 0 20px;
    }

    .number {
        position: static;
        margin: 0 auto 10px auto;

    }

    .image img {
        width: 70%;
        height: auto;
    }

    .merit-section h3 {
        text-align: center;
        font-size: 2rem;
    }

    .inner-content ul {
        font-size: 1.2rem;
        text-align: left;
    }

    h4 {
        text-align: left;
    }

    .small-image.fly-out:nth-child(4) {
        transform: translate(-90%, -55%) scale(0.25);
        opacity: 1;
    }

    .small-image.fly-out:nth-child(5) {
        transform: translate(-10%, -50%) scale(0.25);
        opacity: 1;
    }
}

/* スマートフォンサイズ（最大幅480px） */
@media (max-width: 480px) {
    .merit-item {
        flex-direction: column;
        align-items: center;
        margin-top: 0;
        margin-bottom: 10px;
    }

    .content-left,
    .image-container {
        width: 100%;
        margin: 0;
    }

    .inner-content {
        margin: 0 auto;
        text-align: center;
    }

    .merit-title {
        text-align: left;
        padding-left: 20px;
    }

    .merit-section p {
        text-align: left;
        padding: 0 20px;
    }

    .number {
        position: static;
        margin: 0 auto 10px auto;

    }

    .image img {
        width: 100%;
        height: auto;
    }

    .merit-section h3 {
        text-align: center;
        font-size: 2rem;
        margin-bottom: 10px;
    }

    .inner-content ul {
        font-size: 1.5rem;
        text-align: left;
    }

    .merit-section h4 {
        text-align: left;
        margin-bottom: 20px;
        font-size: 1.2rem;
    }

    .small-image.fly-out:nth-child(4) {
        transform: translate(-90%, -55%) scale(0.25);
        opacity: 1;
    }

    .small-image.fly-out:nth-child(5) {
        transform: translate(-10%, -50%) scale(0.25);
        opacity: 1;
    }

}