.school-meeting-section {
    padding: 40px;
    text-align: center;
    border-radius: 10px;
    background-color: #f9f9fc;
}

.section-title {
    text-align: center;
    font-size: 3.0rem;
    color: #0e388c;
    padding-top: 40px;
    font-weight: 800;
}

.section-description {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 10px;
}

.date-list {
    display: grid;
    gap: 40px;
    padding: 0;
    list-style: none;
    margin: 40px 0;
    grid-template-columns: repeat(3, 1fr);
    /* デフォルトは3列 */
}

.date-item {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.date-item:hover {
    transform: scale(1.05);
}

.date-text {
    font-size: 1.2rem;
    font-weight: bold;
    display: block;
}

.time-text {
    font-size: 1rem;
    margin: 5px;
    display: block;
}

.date-item button {
    padding: 15px;
    font-size: 0.9rem;
    font-weight: bold;
    margin: 10px auto;
    width: 80%;
    height: auto;
    color: #333;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    min-width: 260px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.date-item button::after {
    content: "\f058";
    font-family: "Font Awesome 6 Free"; /* フォントファミリーを指定 */
    font-weight: 900; /* Solid アイコンの場合、font-weight を 900 に設定 */
    color: #1b479c;
    font-size: 1.5rem;
    margin-left: 10px; /* テキストとの余白 */
    transition: color 0.3s ease; /* Smooth transition for color change */
}

.add-to-calendar-btn {
    display: flex;
    justify-content: space-between; /* テキストを左、アイコンを右に配置 */
    align-items: center; /* アイコンとテキストを縦方向で中央揃え */
    position: relative;
    background-color: #f9f9fc; /* ボタン背景色 */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* 影 */
}

.add-to-calendar-btn:hover {
    background-color: #355ba8;
}

.meeting-link {
    margin: 40px;
    font-size: 1.2rem;
}

.meeting-link a {
    color: #1b479c;
    text-decoration: none;
    font-weight: bold;
}

.meeting-link a:hover {
    text-decoration: underline;
}

/* レスポンシブ対応 */
@media (max-width: 1024px) {
    .date-list {
        grid-template-columns: repeat(2, 1fr);
        /* 1024px以下では2列 */
    }
}

@media (max-width: 768px) {
    .date-list {
        grid-template-columns: 1fr;
        /* 480px以下では1列 */
    }
}

@media (max-width: 375px) {
    .section-title {
        text-align: left;
        font-size: 2.5rem;
    }
    
    .section-description {
        font-size: 1.0rem;
        font-weight: 800;
        padding-top: 10px;
        text-align: left;
    }
    .meeting-link {
        margin: 40px 0;
    }
}