.startup-support-section {
    padding: 60px 20px;
    background-color: #f9f9f9;
}

.startup-support-section p {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 10px;
}

.startup-support-title {
    text-align: center;
    font-size: 3.0rem;
    color: #0e388c;
    padding-top: 40px;
    font-weight: 800;
    margin-bottom: 0;
}

.startup-container {
    display: grid;
    width: 90%;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    margin: 60px auto;
}

.startup-block {
    position: relative;
    padding: 10px;
    margin: 20px 20px 0 20px;
    background-color: #f9f9f9;
    z-index: 0;
}

/* Hover時にスケールを1.03倍にする */
.startup-block:hover {
    transform: scale(1.03);
    transition: transform 0.3s ease; /* アニメーションのスピードとスムーズさを調整 */
}

/* 偶数のサブブロックにのみ上にmarginを追加 */
.startup-block:nth-child(even) {
    margin-top: 80px; /* 必要な値に調整 */
}

.startup-image-container {
    position: relative;
    z-index: 2;
}

.startup-image {
    width: 100%;
    border-radius: 20px;
    height: 300px;
    object-fit: cover;
}

.number-circle {
    position: absolute;
    top: -50px;
    left: -40px;
    border-radius: 50%;
    border: #0e388c 2px solid;
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: #0e388c;
    opacity: 0.5;
    z-index: 1;
}

.startup-text-container {
    position: relative;
    width: 80%;
    top: -50px;
    background-color: #f9f9f9;
    padding: 20px 20px 20px 0;
    border-radius: 0 20px;
    text-align: left;
    margin-bottom: -60px;
    z-index: 3;
}

.startup-text-container h3 {
    font-size: 1.7rem;
    font-weight: bold;
    color: #0e388c;
    margin-bottom: 10px;
}

.startup-text-container p {
    font-size: 1.1rem;
    color: #333;
    line-height: 1.6;
}

@media (max-width: 1024px) {
    .startup-container {
        grid-template-columns: 1fr;
    }

    .startup-image {
        height: 450px;
    }

    .startup-block:nth-child(even) {
        margin-top: 30px;
    }
}

@media (max-width: 480px) {
    .startup-support-section {
        padding: 40px 10px;
    }

    .startup-support-section p {
        font-size: 1rem;
    }

    .startup-support-title {
        font-size: 2.1rem;
        padding-top: 20px;
        text-align: left;
        padding-left: 20px;
    }

    .startup-support-description {
        font-size: 1rem;
        text-align: left;
        padding: 0 20px;
    }

    .startup-container {
        width: 100%;
        margin: 60px auto;
        gap: 30px;
    }

    .startup-block {
        margin: 30px 10px 0 10px;
    }

    .startup-block:nth-child(even) {
        margin-top: 30px;
    }

    .startup-image {
        height: 200px;
    }

    .number-circle {
        top: -35px;
        left: -10px;
        width: 3.5rem;
        height: 3.5rem;
        font-size: 1.7rem;
    }

    .startup-text-container {
        width: 90%;
        top: -30px;
        padding: 15px 15px 15px 0;
        margin-bottom: -40px;
    }

    .startup-text-container h3 {
        font-size: 1.5rem;
    }

    .startup-text-container p {
        font-size: 1rem;
    }
}