/* Header.css */

/* 全体のスタイル */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: white;
    color: #333;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2000;
    transition: box-shadow 0.3s ease;
    /* スムーズな影の表示/非表示 */
}

/* スクロール時にのみ影を表示 */
.header.scrolled {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo-section {
    display: flex;
    align-items: center;
}

.logo-section a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
}

.logo {
    width: 50px;
    margin-right: 10px;
}

.school-name {
    font-size: 1.5rem;
    font-weight: bold;
}

/* ハンバーガーメニューアイコンのスタイル */
.hamburger {
    display: none;
    /* デフォルトでは非表示 */
    flex-direction: column;
    cursor: pointer;
}

.hamburger .bar {
    width: 25px;
    height: 3px;
    background-color: #212529;
    margin: 2.25px 0;
    transition: 0.4s;
    border-radius: 2px;
}

.hamburger .bar.open:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.hamburger .bar.open:nth-child(2) {
    opacity: 0;
}

.hamburger .bar.open:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
}

/* ナビゲーションメニュー */
.nav-menu ul {
    list-style: none;
    display: flex;
    gap: 10px;
    font-family: "Noto Sans CJK JP", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif, Arial;
    font-weight: 500;
    align-items: center;
}

.nav-menu ul li {
    position: relative;
}

.nav-menu ul li a {
    color: #333;
    text-decoration: none;
    padding: 9px 10px 10px 10px;
    cursor: pointer;
}

.nav-menu ul li a:hover {
    color: #355ba8;
}

.nav-menu li:not(:first-child):not(:last-child) .menu-item::after {
    content: "\f078";
    font-family: "Font Awesome 6 Free";
    /* フォントファミリーを指定 */
    font-weight: 900;
    /* Solid アイコンの場合、font-weight を 900 に設定 */
    margin-left: 7px;
    color: #212529;
    font-size: 0.7rem;
}

/* お問い合わせボタンのスタイル */
.nav-menu .contact-button {
    margin-left: 20px;
    background-color: #1b479c;
    border-radius: 10px;
    height: 40px;
    width: 150px;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 600;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.nav-menu .contact-button a {
    color: #fff;
    text-decoration: none;
}

.nav-menu .contact-button a:hover {
    color: #f0f0f0;
    cursor: pointer;
}

.nav-menu .contact-button:hover {
    background-color: #355ba8;
}

/* メガメニューのスタイル */
.mega-menu {
    position: fixed;
    /* ウィンドウ全体にメガメニューを表示 */
    top: 55px;
    /* ヘッダーの高さに応じて調整 */
    left: 0;
    right: 0;
    width: 100vw;
    background-color: white;
    color: #333;
    padding: 20px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    opacity: 0;
    /* デフォルトで見えないように */
    visibility: hidden;
    /* 初期は非表示 */
    transition: opacity 0.3s ease, visibility 0.3s ease;
    /* スムーズに表示・非表示 */
}

.dropdown:hover .mega-menu,
.dropdown.open .mega-menu {
    opacity: 1;
    visibility: visible;
}

.mega-menu-content {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 30px;
    width: 100%;
}

.mega-menu-left {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    height: 100%;
}

.mega-menu-image {
    width: 100%;
    min-height: 200px;
    object-fit: cover;
    border-radius: 0 25px 25px 0;
}

.mega-menu-right {
    flex: 3;
    gap: 30px;
    margin: auto 30px;
}

.mega-menu-right .mega-menu-columns {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: left;
    gap: 30px;
}

.mega-menu-columns li {
    list-style: none;
    position: relative;
    padding: 10px 0;
    border-bottom: 1px solid #333;
    width: 100%;
}

.mega-menu-columns li a {
    color: black;
    text-decoration: none;
    display: block;
    position: relative;
    padding-right: 5px;
    width: 100%;
}

.mega-menu-columns .chevron-right::after {
    content: "\f054";
    font-family: "Font Awesome 6 Free";
    /* フォントファミリーを指定 */
    font-weight: 900;
    /* Solid アイコンの場合、font-weight を 900 に設定 */
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #212529;
    font-size: 0.7rem;
}

.mega-menu-columns li a:hover {
    color: #355ba8;
}

/* メディアクエリで画面幅が一定以下の場合のスタイルを追加 */
@media (max-width: 1024px) {
    .hamburger {
        display: flex;
        /* ハンバーガーメニューを表示 */
    }

    .nav-menu {
        position: absolute;
        top: 60px;
        right: 0;
        width: 100%;
        background-color: white;
        flex-direction: column;
        align-items: center;
        display: none;
        /* デフォルトでは非表示 */
        z-index: 1000;
    }

    .nav-menu.open {
        display: block;
        /* メニューが開いているときは表示 */
    }

    .nav-menu ul {
        flex-direction: column;
        gap: 10px;
        width: 100%;
        padding: 0 20px 20px 0;
    }

    .nav-menu ul li {
        width: 100%;
        text-align: left;
        border-bottom: 1px solid #eaeaea;
    }

    .nav-menu ul li a {
        display: block;
        padding: 13px 15px 16px 15px;
        position: relative;
    }

    .nav-menu ul li a::after {
        position: absolute;  /* 絶対位置指定を追加 */
        right: 15px;  /* 右端に寄せる */
        top: 50%;  /* 垂直方向で中央揃え */
        transform: translateY(-50%);  /* 垂直中央に表示 */
        color: #212529;  /* 通常時の色を指定 */
        font-size: 0.7rem;
    }

    .dropdown .mega-menu {
        display: none;
        position: relative;
        width: 100%;
        background-color: white;
        box-shadow: none;
        padding: 0;
        opacity: 0;
        visibility: hidden;
        height: 0;
        overflow: hidden;
        transition: none;
    }

    .dropdown.open .mega-menu {
        display: block;
        opacity: 1;
        visibility: visible;
        height: auto;
        overflow: visible;
        top: 0;
    }

    .mega-menu .mega-menu-content {
        display: block;
        width: 100%;
    }

    .mega-menu-left {
        display: block;
    }

    .mega-menu-left img {
        display: none;
        width: 100%;
        height: 100px;
        border-radius: 10px;
    }

    .mega-menu-right {
        display: block;
        width: 100%;
    }

    .mega-menu-right .mega-menu-columns {
        display: block;
    }

    .mega-menu ul {
        display: block;
    }

    .mega-menu ul li {
        border-bottom: 1px solid #eaeaea;
    }

    .mega-menu ul li a {
        padding: 10px 15px;
        color: #212529;
        text-decoration: none;
    }

    .mega-menu-columns li {
        padding: 0;
    }

    .dropdown>a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
    }

    .contact-button {
        margin: 20px 0 30px 0;
    }
}

/* スマートフォンサイズ（最大幅480px） */
@media (max-width: 480px) {
    .school-name {
        font-size: 1.3rem;
    }
  }