/* FindWorkSection.css */

.cirtificate-section {
    padding: 60px 20px;
    background-color: #f9f9f9;
}

.cirtificate-section p {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 10px;
}

.cirtificate-title {
    text-align: center;
    font-size: 3.0rem;
    color: #0e388c;
    padding-top: 40px;
    font-weight: 800;
    margin-bottom: 0;
}

/* カードのコンテナ */
.cirtificate-cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    margin: 40px auto;
    width: 95%;
}

/* 各カードのスタイル */
.cirtificate-card {
    background-color: white;
    width: 32%;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
}

.cirtificate-card img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
}

.cirtificate-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

/* カード内のテキスト */
.cirtificate-card h3 {
    font-size: 1.8rem;
    font-weight: bold;
    color: #1b479c;
    margin-top: 20px;
}

.cirtificate-card p {
    font-size: 1.2rem;
    color: #555;
    margin: 10px 0;
}

/* 矢印 */
.cirtificate-card .arrow {
    position: absolute;
    bottom: 10px;
    right: 20px;
    font-size: 2rem;
    color: #1b479c;
}

@media (max-width: 1024px) {
    .cirtificate-cards {
        width: 95%;
        gap: 20px;
    }

    .cirtificate-card {
        width: 48%;
    }
}

@media (max-width: 768px) {
    .cirtificate-card {
        width: 100%;
        margin-bottom: 30px;
    }
}

@media (max-width: 480px) {
    .cirtificate-section {
        padding: 40px 10px;
    }

    .cirtificate-title {
        font-size: 2.5rem;
        padding-top: 20px;
        text-align: left;
        padding-left: 20px;
    }

    .cirtificate-description {
        font-size: 1.0rem;
        text-align: left;
        padding: 0 20px;
    }

    .cirtificate-cards {
        gap: 5px;
        width: 100%;
    }

    .cirtificate-card {
        width: 100%;
        padding: 15px;
        margin-bottom: 20px;
    }

    .cirtificate-card h3 {
        font-size: 1.5rem;
    }

    .cirtificate-card p {
        font-size: 1rem;
    }

    .cirtificate-card .arrow {
        font-size: 1.5rem;
    }
}