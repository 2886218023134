.course-section {
    padding: 40px;
    text-align: center;
    margin-bottom: 80px;
}

.course-section p {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 10px;
}

.course-title {
    text-align: center;
    /* センタリング */
    font-size: 3.0rem;
    /* タイトルのフォントサイズを大きく */
    /* 下部に余白を追加 */
    color: #0e388c;
    padding-top: 40px;
    font-weight: 800;
}

.course-grid {
    margin-top: 40px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(1, 1fr);
    /* Default for mobile (1 column) */
}

.course-item {
    position: relative;
    /* cursor: pointer; */
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.course-item:hover {
    transform: scale(1.05);
}

.course-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.course-overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 30%;
    background: rgba(27, 71, 156, 0.5);
    /* Dark overlay */
    display: flex;
    align-items: center;
    justify-content: center;
}

.course-title-text {
    color: white;
    font-size: 1.5em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

/* For larger screens */
@media (min-width: 600px) {
    .course-grid {
        grid-template-columns: repeat(2, 1fr);
        /* 2 columns for mid-size screens */
    }
}

@media (min-width: 1024px) {
    .course-grid {
        grid-template-columns: repeat(3, 1fr);
        /* 3 columns for large screens */
    }
}

@media (max-width: 480px) {
    .course-section {
        padding: 20px;
        margin-bottom: 40px;
    }

    .course-title {
        font-size: 3rem;
        padding-top: 20px;
        text-align: left;
        padding-left: 20px;
    }

    .course-section p {
        font-size: 1.0rem;
        text-align: left;
        padding-left: 20px;
    }

    .course-grid {
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }

    .course-item {
        border-radius: 5px;
    }

    .course-title-text {
        font-size: 1.2em;
    }
}