.floating-buttons {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 15px;
    background: rgba(255, 255, 255, 0.9);
    padding: 10px 15px;
    border-radius: 40px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    z-index: 1000;
}

/* 個々のボタン */
.fab-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #333;
    font-size: 1rem;
    transition: transform 0.2s ease-in-out;
    width: 30%;
}

.fab-item:hover {
    transform: scale(1.1);
}

/* アイコンのデザイン */
.fab-item i {
    font-size: 1.5rem;
    color: #1b479c;
    margin-bottom: 3px;
}

/* モバイルのみ表示 */
@media (min-width: 768px) {
    .floating-buttons {
        display: none;
    }
}