.learning-content {
    width: 100%;
    margin-top: 40px;
    padding: 0;
}

.page-title {
    text-align: left;
    padding: 40px 40px 60px 70px;
}

.page-title h1 {
    font-size: 3rem;
    color: #0e388c;
    font-weight: 800;
    margin-bottom: 10px;
}

.page-title p {
    text-align: left;
    color: #333;
}

.learning-image-container {
    position: relative;
    width: 100%;
    display: flex;
    max-height: 60vh;
    min-height: 60vh;
    overflow: hidden;
    justify-content: flex-end;
}

.top-image {
    width: auto;
    height: auto;
    max-width: 80vw;
    object-fit: cover;
    border-radius: 25px 0 0 25px; /* 左上の角を丸める */
}

.image-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    /* テキストに影をつけて読みやすくする */
}

@media (max-width: 480px) {
    .page-title {
        padding: 40px 20px 20px 20px;
    }

    .page-title h1 {
        font-size: 2rem;
        margin-bottom: 0px;
    }

    .learning-image-container {
        padding-left: 20px;
        height: 40%;
    }

    .top-image {
        max-width: 100%;
        border-radius: 15px 0 0 15px;
    }

    .image-text {
        font-size: 1.5rem;
    }
}