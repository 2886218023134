/* Curriculum.css */

.curriculum-section {
    padding: 40px 20px;
    background-color: #f9f9f9;
}

.curriculum-section p {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 10px;
}

.curriculum-title {
    text-align: center;
    /* センタリング */
    font-size: 3.0rem;
    /* タイトルのフォントサイズを大きく */
    /* 下部に余白を追加 */
    color: #0e388c;
    padding-top: 40px;
    font-weight: 800;
}

.curriculum-table {
    width: 80%;
    margin: 40px auto;
    border-collapse: collapse;
    background-color: #f9f9f9;
    border-radius: 10px;
    overflow: hidden;
}

.curriculum-table th,
.curriculum-table td {
    border: 1px solid #ddd;
    padding: 15px 20px;
    font-size: 1.1rem;
    text-align: center;
}

.curriculum-table th {
    background-color: #1b479c;
    color: #fff;
    font-weight: bold;
    width: 20%;
}

.category {
    background-color: #1b479c;
    color: white;
    font-weight: bold;
}

.curriculum-table td {
    background-color: #f9f9f9;
    font-size: 1.2rem;
}

.curriculum-table tr:nth-child(even) td {
    background-color: #f2f2f2;
}

@media (max-width: 1024px) {
    .curriculum-table {
        width: 100%;
        min-width: 480px;  /* スマホ用の最小幅 */
        margin: 20px 0;
    }

    .curriculum-table th,
    .curriculum-table td {
        font-size: 1.0rem;
        padding: 10px;
    }
}

/* スマホ対応 */
@media (max-width: 480px) {
    .curriculum-title {
        font-size: 3rem;
        padding-top: 20px;
        text-align: left;
        padding-left: 20px;
    }

    .curriculum-section p {
        font-size: 1.0rem;
        text-align: left;
        padding-left: 20px;
    }

    .curriculum-table-wrapper {
        width: 100%;
        overflow-x: auto;  /* スクロール対応 */
    }

    .curriculum-table {
        width: 100%;
        min-width: 480px;  /* スマホ用の最小幅 */
        margin: 20px 0;
    }

    .curriculum-table th,
    .curriculum-table td {
        font-size: 1.0rem;
        padding: 10px;
        white-space: nowrap;  /* テキストを1行に収める */
    }
}