.video-contents {
    padding: 40px;
    background-color: #fff;
}

.video-contents p {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 10px;
}

.video-contents-title {
    text-align: center;
    font-size: 3.0rem;
    color: #0e388c;
    padding-top: 40px;
    font-weight: 800;
}

.content-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin: 40px 60px 60px 60px;
}

.content-item {
    position: relative;
    overflow: hidden;
    padding: 25px;
    border: 2px solid #0e388c;
    border-radius: 15px;
    text-align: center;
    font-size: 1.3rem;
    font-weight: 600;
    color: #0e388c;
    display: flex;
    justify-content: center;
    align-items: center;
    /* 背景グラデーションを設定 */
    background: linear-gradient(to right, #1b479c 50%, #fff 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease, box-shadow 0.3s;
}

.content-item:hover {
    background-position: left bottom;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.content-item .content-icon {
    background-color: #1b479c;
    color: #fff;
    border-radius: 50%;
    padding: 5px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.content-item:hover .content-icon {
    background-color: #fff;
    color: #0e388c;
}

@media (max-width: 1024px) {
    .content-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {

    .content-grid {
        grid-template-columns: repeat(2, 1fr);
        margin: 40px 0 60px 0;
    }
}

@media (max-width: 480px) {
    .content-grid {
        grid-template-columns: repeat(2, 1fr);
        margin: 40px 0 60px 0;
        gap: 20px;
    }

    .video-contents {
        padding: 20px;
    }

    .video-contents-title {
        font-size: 2.3rem;
        padding-top: 20px;
        text-align: left;
        padding-left: 20px;
    }

    .video-contents p {
        font-size: 1.0rem;
        text-align: left;
        padding-left: 20px;
    }

    .content-item {
        padding: 15px;
        font-size: 1rem;
    }

    .content-item .content-icon {
        width: 30px;
        height: 30px;
    }
}