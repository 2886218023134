#metaverse-section {
    background-color: #f9f9f9;
    padding: 40px 0;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 40px;
}

.text-center {
    text-align: center;
    margin-bottom: 40px;
}

.text-center h2 {
    font-size: 3rem;
    color: #1b479c;
}

.text-center p {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 10px;
}

/* 左右に並べるためのレイアウト設定 */
.content-section {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.left-section {
    position: relative;
    width: 60%;
    /* 左側の幅 */
    margin-right: 20px;
    /* 右側とのスペース */
}

.main-video {
    width: 100%;
    border-radius: 10px;
}

.bottom-right-image {
    position: absolute;
    bottom: -20px;
    right: -20px;
    width: 150px;
    height: auto;
    z-index: 2;
}

.right-section {
    width: 40%;
    /* 右側の幅 */
}

.right-section ul {
    list-style: none;
    padding-left: 0;
    margin-left: 20px;
}

.right-section ul li {
    position: relative;
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
    padding: 20px 0;
    margin-bottom: 10px;
    text-align: left;
    line-height: 2rem;
    border-bottom: #333 1px solid;
    padding-left: 30px;
}

.right-section ul li::before {
    content: "\f111";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    color: #0e388c;
    font-size: 0.8rem;
    position: absolute;
    left: 0;
    margin-right: 20px;
}

@media (max-width: 1024px) {
    .container {
        padding: 40px 0px;
    }

    .text-center h2 {
        margin-top: 20px;
        font-size: 3rem;
    }

    .text-center p {
        font-size: 1.2rem;
    }

    .content-section {
        flex-direction: column;
        align-items: center;
    }

    .left-section {
        width: 100%;
        margin-right: 0;
    }

    .right-section {
        width: 80%;
        margin-top: 20px;
    }

    .main-video {
        width: 90%;
        height: 500px;
        object-fit: cover;
        border-radius: 10px;
    }

    .bottom-right-image {
        position: absolute;
        bottom: -20px;
        right: 0px;
        width: 200px;
        height: auto;
        z-index: 2;
        border-radius: 10px;
    }

    .right-section ul {
        margin: 0 10px;
    }

    .right-section ul li {
        padding-left: 80px;
        font-size: 1.5rem;
        line-height: 2.5rem;
    }

    .right-section ul li::before {
        left: 60px;
    }
}

@media (max-width: 480px) {
    .container {
        padding: 40px 0px;
    }

    .text-center h2 {
        margin-top: 20px;
        font-size: 3rem;
    }

    .text-center p {
        font-size: 1.2rem;
    }

    .content-section {
        flex-direction: column;
        align-items: center;
    }

    .left-section, .right-section {
        width: 100%;
        margin-right: 0;
    }

    .main-video {
        width: 100%;
        height: 400px;
        object-fit: cover;
        border-radius: 0px;
    }

    .bottom-right-image {
        position: absolute;
        bottom: -20px;
        right: 0px;
        width: 150px;
        height: auto;
        z-index: 2;
        border-radius: 10px;
    }

    .right-section ul {
        margin: 0 10px;
    }

    .right-section ul li {
        padding-left: 30px;
    }

    .right-section ul li::before {
        left: 10px;
    }
}
