/* 全体のスタイルをリセット */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* heroセクション全体 */
.hero-section {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

/* 画像の枠のスタイル */
.hero-image-container {
    position: absolute;
    width: 75%; /* 枠のサイズは固定 */
    height: 85%;
    top: 80px;
    right: 0;
    border-radius: 30px 0 0 30px; /* 左下の角を丸める */
    overflow: hidden; /* 画像のはみ出し部分を隠す */
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* hero-imageを重ねるための基本設定 */
.hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    transition: opacity 2s ease-in-out, transform 6s ease-in-out;
    opacity: 0;
    transform: scale(1);
}

/* アクティブな画像にフェードイン・ズームインアニメーションを適用 */
.hero-image.active {
    opacity: 1;
    transform: scale(1.1); /* ズームイン効果 */
}

/* テキストを左側に配置 */
.hero-text {
    position: relative;
    width: 25%;
    color: #333;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
    padding-left: 40px;
    padding-top: 30vh;
}

.hero-text h1 {
    font-size: 3rem;
    margin-bottom: 50px;
    opacity: 0;
    animation: fadeInText 1s ease-in-out forwards;
}

/* 各行をフェードインするアニメーション */
@keyframes fadeInText {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* h1 テキストの表示エリアの高さを固定しておく */
.hero-text h1 {
    min-height: 3rem;
    line-height: 3rem;
}

.top-contact-button-section {
    background-color: #f9f9f9;
}

@media (max-width: 1024px) {
    .hero-section {
        flex-direction: column;
        align-items: center;
        padding: 80px 0 0 0;
    }

    .hero-section {
        flex-direction: column;
        align-items: center;
        padding: 80px 0 0 0;
    }

    .hero-image-container {
        position: relative;
        width: 100%;
        height: 68%;
        top: 0;
        right: 0;
        margin-left: 200px;
    }

    .hero-text {
        width: 100%;
        margin: auto 200px;
        padding: 0 100px;
        height: auto; /* テキストの高さを自動調整 */
        text-align: center; /* テキストを中央揃えに変更 */
        justify-content: center;
        display: flex;
        align-items: flex-start; /* 縦方向に中央揃え */
    }

    .hero-text h1 {
        font-size: 4.5rem;
        margin-bottom: 15px;
        line-height: 5.5rem;
    }
}

/* タブレットサイズ（最大幅768px） */
@media (max-width: 768px) {
    .hero-section {
        flex-direction: column;
        align-items: center;
        padding: 80px 0 0 0;
    }

    .hero-image-container {
        position: relative;
        width: 100%;
        height: 68%;
        top: 0;
        right: 0;
        margin-left: 200px;
    }

    .hero-text {
        width: 100%;
        padding-left: 100px;
        padding-top: 10px;
        height: auto; /* テキストの高さを自動調整 */
        text-align: center; /* テキストを中央揃えに変更 */
        justify-content: center;
        display: flex;
        align-items: flex-start; /* 縦方向に中央揃え */
    }

    .hero-text h1 {
        font-size: 3.5rem;
        margin-bottom: 15px;
        line-height: 4rem;
    }
}

/* スマートフォンサイズ（最大幅480px） */
@media (max-width: 480px) {
    .hero-section {
        flex-direction: column;
        align-items: center;
        padding: 80px 0 0 0;
    }

    .hero-image-container {
        position: relative;
        width: 100%;
        height: 60%;
        top: 0;
        right: 0;
        margin-left: 50px;
    }

    .hero-text {
        width: 100%;
        padding-left: 40px;
        padding-top: 10px;
        height: auto; /* テキストの高さを自動調整 */
        text-align: center; /* テキストを中央揃えに変更 */
        justify-content: center;
        display: flex;
        align-items: flex-start; /* 縦方向に中央揃え */
    }

    .hero-text h1 {
        font-size: 2.5rem;
        margin-bottom: 15px;
        line-height: 0.5rem;
    }

}