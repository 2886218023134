/* case-studySection.css */

/* 全体のスタイル */
.case-study-section {
    padding: 60px 20px;
	background-color: #f9f9f9;
}

.case-study-section p {
    font-size: 1.2rem;
    font-weight: 800;
    padding-top: 10px;
}

.case-study-title {
    text-align: center;
    font-size: 3.0rem;
    color: #0e388c;
    padding-top: 40px;
    font-weight: 800;
}

/* コンテンツ全体のレイアウト */
.case-study-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin-top: 40px;
}

/* ケーススタディブロック */
.case-study-block {
    flex: 0 1 calc(90% - 20px); /* 2列レイアウト */
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    padding: 20px;
    text-align: center;
	background-color: #ffffff;
}

.case-study-block:hover {
    transform: scale(1.03);
}

/* h3 をブロックの一番上で中央に配置 */
.case-study-block h3 {
    font-size: 2rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

/* 画像と説明テキストのレイアウト */
.case-study-content {
    display: flex;
    align-items: center;
    gap: 20px;
    text-align: left;
}

/* 画像は左側 */
.case-study-content img {
    width: 35%;
    max-width: 300px;
	aspect-ratio: 1 / 1;
    border-radius: 10px;
	object-fit: cover;
}

/* 説明文は右側 */
.case-study-text {
    flex: 1; /* 残りのスペースを使用 */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* 説明テキスト */
.case-study-text p {
    font-size: 1.3rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 15px;
}

/* サブアイテムのリスト */
.case-study-text ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.case-study-text ul li {
    position: relative;
    font-size: 1.2rem;
    margin-bottom: 10px;
    line-height: 1.5;
    display: flex;
	align-items: flex-start;
}

.case-study-text ul li i {
    color: #1b479c;
    margin: 4px 8px;
}

/* レスポンシブ対応 */
@media (max-width: 1024px) {
    .case-study-block {
        flex: 0 1 calc(100% - 20px); /* 1列レイアウト */
    }

    .case-study-title {
        font-size: 2.5rem;
    }
}

@media (max-width: 768px) {
    .case-study-block {
        flex: 0 1 calc(100% - 20px); /* スマホでは1列 */
    }

    .case-study-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .case-study-content img {
        width: 60%;
        max-width: 250px;
        margin-bottom: 15px;
    }

    .case-study-text {
        text-align: left;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .case-study-section {
        padding: 40px 10px;
    }

    .case-study-title {
        font-size: 2rem;
        padding-top: 20px;
        text-align: left;
        padding-left: 20px;
    }

	.case-study-section p {
		font-size: 1.2rem;
		font-weight: 800;
		padding: 0 20px;
		text-align: left;
	}

    .case-study-block {
        padding: 15px;
    }

	.case-study-block h3 {
		font-size: 1.7rem;
		padding: 10px;
		text-align: left;
	}

    .case-study-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .case-study-content img {
        width: 100%;
		aspect-ratio: 2 / 1;
        max-width: 350px;
    }

    .case-study-text {
        text-align: left;
    }

    .case-study-text p {
        font-size: 1.5rem;
    }

    .case-study-text ul li {
        font-size: 1.1rem;
    }
}